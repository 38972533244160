
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class OutlinedSummaryCard extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: false }) readonly subtitle?: string
  @Prop({ required: true }) readonly description!: string
  @Prop({ required: false }) readonly tags?: string[]
  @Prop({ required: false }) readonly state!: string
}
