
import { Component, Vue } from "vue-property-decorator"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import {
  ApplyForMissionStaffingPositionMutation,
  ApplyForMissionStaffingPositionMutationMutation,
  CancelMissionStaffingApplicationMutation,
  ConsultantProfileFragmentFragment,
  MissionStaffingPositionDetailFragmentFragment,
  MissionStaffingPositionsQuery,
  MissionStaffingPositionsQueryQuery,
} from "@/gql"
import { FetchResult } from "apollo-link"
import moment from "moment"
import { Getter, Action } from "vuex-class"
import { Namespaces } from "@/constants"
import { RequireProfilePayload } from "@/store/profile"
import OutlinedSummaryCard from "@/components/content/OutlinedSummaryCard.vue"
import ConsultantWallet from "@/components/consultant/ConsultantWallet.vue"
import EditConsultantInvoiceForm from "@/components/forms/EditConsultantInvoiceForm.vue"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"

const namespace = Namespaces.Profile

@Component({
  components: {
    AppDetailPage,
    ConsultantWallet,
    EditConsultantInvoiceForm,
    OutlinedSummaryCard,
    FeedBackMessage,
  },
})
export default class StaffingPositionDetail extends Vue {
  readonly applyForMissionStaffingPositionMutation = ApplyForMissionStaffingPositionMutation
  readonly MissionStaffingPositionsQuery = MissionStaffingPositionsQuery

  @Action("requireProfile", { namespace })
  requireProfile!: (payload: RequireProfilePayload) => void

  @Getter("getProfile", { namespace })
  consultantProfile!: ConsultantProfileFragmentFragment

  tabIndex: number | null = null
  showConfirmModal = false
  cancelling = false
  applying = false
  showCancelDialog = false
  showCreateInvoiceForm = false
  expressionOfInterest = ""
  staffingPosition: MissionStaffingPositionDetailFragmentFragment | null = null
  invoiceMetadata: Record<string, any> | null = null

  get tabs() {
    let tabs = ["Details"]
    if (this.staffingPlacement && this.staffingPosition?.invoiceable) tabs.push("Payments")
    return tabs
  }

  get application() {
    if (this.staffingPosition) {
      return this.consultantProfile?.missionStaffingApplications?.data.find(
        (application) => application.missionStaffingPosition.id === this.staffingPosition!.id
      )
    }
    return undefined
  }

  get staffingPlacement() {
    if (this.staffingPosition) {
      return this.consultantProfile?.missionStaffingPlacements?.data.find(
        (placement) => placement?.missionStaffingPosition?.id === this.staffingPosition!.id
      )
    }
    return undefined
  }

  get consultantInvoices(): Record<string, any>[] | undefined {
    return this.staffingPlacement?.consultantInvoices
  }

  get state() {
    if (this.staffingPlacement) {
      // staffed
      if (this.staffingPosition!.missionLifecycle.state === "completed") {
        // mission completed
        return {
          color: "green white--text",
          text: "completed",
          date: this.staffingPosition!.missionLifecycle.missionExecution!.completionDate,
        }
      } else {
        // Mission in execution or approved
        return {
          color: "green white--text",
          text: this.staffingPosition!.missionLifecycle.missionExecution ? "executing" : "approved",

          date: this.staffingPosition!.missionLifecycle.missionExecution
            ? this.staffingPosition!.missionLifecycle.missionExecution.commencementDate
            : this.staffingPlacement.createdAt,

          displayText: this.staffingPosition!.missionLifecycle.missionExecution
            ? "Commenced"
            : undefined,
        }
      }
    }
    if (this.application && ["rejected", "closed"].includes(this.application.state)) {
      // Application rejected or closed
      return {
        color: "error white--text",
        text: this.application.state,
        date: this.application.updatedAt,
      }
    }
    if (this.application && this.application.state === "pending") {
      // Pending application
      return {
        color: "amber white--text",
        text: "applied",
        date: this.application.createdAt,
      }
    }
    //  Published
    return { color: "light-blue lighten-5 white--text", text: "Published" }
  }

  get consultantMissionWallet() {
    const contractSum = this.consultantInvoices?.reduce((sum, cur) => sum + cur.total, 0) || 0,
      totalOutstanding =
        this.consultantInvoices
          ?.filter((i) => !i.paidAt)
          .reduce((sum, cur) => sum + cur.total, 0) || 0

    return {
      totalOutstanding,
      totalEarned: contractSum - totalOutstanding,
    }
  }

  getMissionDuration(startDate: string, endDate: string) {
    const duration = moment(endDate).diff(startDate, "weeks")
    return duration === 0 ? "1 week" : `${duration} weeks`
  }

  openCreateInvoiceForm() {
    this.showCreateInvoiceForm = true
    this.invoiceMetadata = {
      missionLifecycle_name: this.staffingPosition!.missionLifecycle.name,
      missionStaffingPosition_name: this.staffingPosition!.name,
      consultantId: this.consultantProfile.id,
      missionLifecycleId: this.staffingPosition!.missionLifecycle.id,
      missionStaffingPlacementId: this.staffingPlacement!.id,
    }
  }

  onQueryResult(result: FetchResult<MissionStaffingPositionsQueryQuery>) {
    if (result.data) {
      this.staffingPosition = result.data.missionStaffingPositions.data[0]
    }
  }

  async onApplyForStaffingPosition() {
    try {
      this.applying = true

      const result = await this.$apollo.mutate<ApplyForMissionStaffingPositionMutationMutation>({
        mutation: ApplyForMissionStaffingPositionMutation,
        variables: {
          id: this.staffingPosition!.id,
          expressionOfInterest: this.expressionOfInterest,
        },
      })

      if (result && result.data) {
        const error = result.data.applyForMissionStaffingPosition.error
        if (error) this.addMutationError(error)
        else {
          this.addSuccess("Application submitted successfully!")
          this.showConfirmModal = false

          this.requireProfile({
            provider: this.$apolloProvider,
            reload: true,
          })
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.applying = false
    }
  }

  async onCancelApplication() {
    try {
      this.cancelling = true

      const result = await this.$apollo.mutate({
        mutation: CancelMissionStaffingApplicationMutation,
        variables: {
          id: this.application!.id,
        },
      })

      if (result && result.data) {
        const error = result.data.cancelMissionStaffingApplication.error
        if (error) this.addMutationError(error)
        else {
          this.addSuccess("Application cancelled successfully!")
          this.showCancelDialog = false

          this.requireProfile({
            provider: this.$apolloProvider,
            reload: true,
          })
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.cancelling = false
    }
  }

  created() {
    if (!this.consultantProfile) {
      this.requireProfile({ provider: this.$apolloProvider })
    }
  }
}
