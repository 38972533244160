
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class ConsultantWallet extends Vue {
  @Prop()
  readonly wallet!: {
    totalOutstanding: string
    totalEarned: string
  }

  @Prop({
    default: "Wallet",
  })
  readonly label?: string

  @Prop() readonly red?: boolean
}
